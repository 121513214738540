import { useModals } from "@mantine/modals";

export const useCloseModal = () => {
  const { closeModal } = useModals();

  const closeSpecificModal = ({ modalId }: { modalId: string }) => {
    closeModal(modalId);
  };

  return { closeSpecificModal };
};
