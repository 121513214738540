import { Group, Menu, Button, MenuProps, ButtonProps } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import Link from "next/link";
import Cookies from "js-cookie";
import { WeddingCreationRouteCookieName } from "@/types/auth";
import { DropdownMenuProps } from "@/types/navigation";

const dropdownStyles: MenuProps["styles"] = {
  dropdown: {
    border: "1px solid #ADADAD",
    borderRadius: "12px",
  },
  divider: {
    backgroundColor: "#ADADAD",
    height: "1px",
  },
};

const buttonStyles: React.CSSProperties = {
  borderRadius: 50,
  zIndex: 5,
  cursor: "pointer",
  backdropFilter: "blur(10px)",
};

const buttonProps: ButtonProps = {
  color: "#fff",
  variant: "outline",
  fz: "15px",
  maw: "210px",
  fw: 600,
  lh: "0.5",
};

const DropdownMenu = ({ buttonText, menuItems }: DropdownMenuProps) => (
  <Menu trigger="hover" styles={dropdownStyles}>
    <Menu.Target>
      <Button
        {...buttonProps}
        style={buttonStyles}
        onClick={() => {
          Cookies.remove(WeddingCreationRouteCookieName);
        }}
        rightSection={<IconChevronDown size={14} color="white" />}
      >
        {buttonText}
      </Button>
    </Menu.Target>
    <Menu.Dropdown c="#3F3F3F" fw={400} lh="1.21" fz={12}>
      {menuItems.map((item, index) =>
        item.link ? (
          <Link href={item.link} key={index}>
            <Menu.Item onClick={item.onClick}>{item.text}</Menu.Item>
          </Link>
        ) : (
          <Menu.Item onClick={item.onClick} key={index}>
            {item.text}
          </Menu.Item>
        )
      )}
    </Menu.Dropdown>
  </Menu>
);

export default DropdownMenu;
