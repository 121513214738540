import { Button, Stack, Box } from "@mantine/core";
import { useForm } from "react-hook-form";
import { setPasswordType } from "@/types/common";
import CustomPasswordInputField from "../form/CustomPasswordField";
import { useApi } from "@/hooks/useApi";
import { getQueryClient } from "@/api";
import { setPasswordModalId } from "@/data/modal";
import { useCloseModal } from "@/hooks/useCloseModal";
import { useQueryClient } from "@tanstack/react-query";
import { contract } from "contract";
import PasswordMatchIndicator from "@/components/profile/PasswordMatchIndicator";
import { ToastStatus, useCustomToast } from "@/hooks/useToast";
import { validatePasswordLength } from "@/components/common/validatePasswordLength";

const SetPasswordForm = () => {
  const hform = useForm<setPasswordType>({
    mode: "onChange",
    defaultValues: {
      newPassword: "",
      repeatedPassword: "",
    },
  });
  const mutateQueryClient = useQueryClient();
  const { closeSpecificModal } = useCloseModal();
  const { makeApiCall } = useApi();
  const { showToast } = useCustomToast();
  const createUserPassword = ({ payload }: { payload: setPasswordType }) => {
    if (payload.newPassword !== payload.repeatedPassword) {
      showToast({
        status: ToastStatus.error,
        id: "errorToastId",
        message: "Passwords Don't Match.",
      });
      return;
    }
    makeApiCall({
      fetcherFn: async () => {
        const response = await getQueryClient().auth.setPassword.mutation({
          body: payload,
        });
        return response;
      },
      onSuccessFn: () => {
        hform.reset();
        closeSpecificModal({ modalId: setPasswordModalId });
        mutateQueryClient.invalidateQueries([contract.user.getProfile.path]);
      },
      successMsgProps: { message: "Password created successfully." },
    });
  };
  const { watch } = hform;

  return (
    <form
      onSubmit={hform.handleSubmit((data) => {
        createUserPassword({ payload: data });
      })}
    >
      <Stack
        pt={{ base: 15, sm: 15, md: 30 }}
        pb={15}
        maw={500}
        w="100%"
        gap={20}
        h="100%"
      >
        <CustomPasswordInputField
          hform={hform}
          name="newPassword"
          placeholder="New Password"
          w="100%"
          rules={{
            required: true,
            validate: {
              validate: validatePasswordLength,
            },
          }}
        />
        <Box>
          <CustomPasswordInputField
            hform={hform}
            name="repeatedPassword"
            placeholder="Confirm New Password"
            w="100%"
            rules={{ required: true }}
          />
          <PasswordMatchIndicator
            password={watch("newPassword")}
            confirmPassword={watch("repeatedPassword")}
          />
        </Box>

        <Button
          w="100%"
          ff="Inter"
          type="submit"
          fw="400"
          fz="18px"
          radius="100px"
          color="primaryPink.2"
          size="lg"
        >
          Set Password
        </Button>
      </Stack>
    </form>
  );
};

export default SetPasswordForm;
