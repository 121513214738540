import { Text, TextProps } from "@mantine/core";

const SectionTitle = ({
  children,
  ...props
}: { children: React.ReactNode } & TextProps) => {
  return (
    <Text
      fz={{ base: 32, sm: 48 }}
      lh="1.28"
      ff="Vesper Libre"
      fw={700}
      c="black"
      {...props}
    >
      {children}
    </Text>
  );
};
export default SectionTitle;
