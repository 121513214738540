import classes from "@/styles/link.module.css";
import { Button, ButtonProps } from "@mantine/core";

const UnStyledButton = ({
  label,
  onClick,
  ...props
}: { label: string; onClick: () => void } & ButtonProps) => {
  return (
    <Button
      variant="transparent"
      size="compact-sm"
      className={classes.customLink}
      ff="Inter"
      fw={700}
      fz={18}
      c="white"
      w="100%"
      justify="flex-start"
      onClick={onClick}
      p={0}
      {...props}
    >
      {label}
    </Button>
  );
};
export default UnStyledButton;
