import { Accordion } from "@mantine/core";
import { IconCaretDownFilled } from "@tabler/icons-react";
import classes from "@/styles/link.module.css";

const CustomAccordion = ({
  accordionControlLabel,
  children,
}: {
  accordionControlLabel: string;
  children: React.ReactNode;
}) => {
  return (
    <Accordion
      chevronPosition="right"
      variant="unstyled"
      chevron={<IconCaretDownFilled className={classes.icon} />}
      styles={{
        control: { paddingInline: 0 },
        label: {
          fontSize: 18,
          lineHeight: "1.21",
          fontWeight: 600,
        },
      }}
      px={0}
    >
      <Accordion.Item value={accordionControlLabel}>
        <Accordion.Control c="white">{accordionControlLabel}</Accordion.Control>
        <Accordion.Panel>{children}</Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};
export default CustomAccordion;
