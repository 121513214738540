import { Text, TextProps } from "@mantine/core";

const HeroTitle = ({
  children,
  ...props
}: { children: React.ReactNode } & TextProps) => {
  return (
    <Text
      fz={{ base: 36, sm: 80 }}
      ff="Vesper Libre"
      lh="1.28"
      fw={700}
      c="black"
      {...props}
    >
      {children}
    </Text>
  );
};
export default HeroTitle;
