import { Text, TextProps } from "@mantine/core";

const SubSectionTitle = ({
  children,
  id,
  ...props
}: { children: React.ReactNode; id?: string } & TextProps) => {
  return (
    <Text
      fz={{ base: 28, sm: 36 }}
      lh="1.28"
      ff="Vesper Libre"
      fw={500}
      c="black"
      id={id}
      {...props}
    >
      {children}
    </Text>
  );
};
export default SubSectionTitle;
