import { Text, TextProps } from "@mantine/core";

const HeroSubTitle = ({
  children,
  ...props
}: { children: React.ReactNode } & TextProps) => {
  return (
    <Text
      fz={{ base: 24, sm: 32 }}
      lh="1.25"
      ff="roboto"
      fw={700}
      c="black"
      {...props}
    >
      {children}
    </Text>
  );
};
export default HeroSubTitle;
