import { Box, Text, Group, Button, Stack } from "@mantine/core";
import { useScreenBreakpoints } from "@/contexts/ScreenBreakpointsProvider";
import { useForm } from "react-hook-form";
import CustomInputField from "../form/CustomInputField";
import CustomPasswordInputField from "@/components/form/CustomPasswordField";
import { ToastStatus, useCustomToast } from "@/hooks/useToast";
import { validatePasswordLength } from "@/components/common/validatePasswordLength";
import { UserDataType } from "@/types/auth";
import { getRoleFromUserDetails } from "@/root/utils";
import { UserRoleEnum } from "contract/enum";

export type CustomInputFieldType = {
  confirmationText: string;
};

export type CustomPasswordInputFieldType = {
  newPassword: string;
};

export const DeleteAccountConfirmModal = ({
  userDetails,
  handleContinue,
  handleCancel,
}: {
  userDetails: UserDataType;
  handleContinue: () => void;
  handleCancel: () => void;
}) => {
  const { isMobile, isTablet, is4k } = useScreenBreakpoints();
  const userRole = getRoleFromUserDetails(userDetails);
  return (
    <Stack gap={0} p={10}>
      <Text
        fz={{ base: 18, sm: 20, md: 22, lg: 24, xl: 26 }}
        fw="700"
        ta="center"
      >
        Are you sure you want to delete your account?
      </Text>
      {(userRole === UserRoleEnum.spouse ||
        userRole === UserRoleEnum.planner ||
        userRole === UserRoleEnum.collaborator) && (
        <Text
          maw={{ base: 420, lg: 500 }}
          c="red"
          px="24px 0"
          mx="auto"
          fw="500"
          ta="center"
          fz={{ base: 14, sm: 14, md: 14, lg: 16, xl: 18 }}
        >
          NOTE: All tasks will be reassigned to the respective project owner,
          and any created wedding projects will be deleted upon account
          deletion!
        </Text>
      )}
      {userRole === UserRoleEnum.proPlanner && (
        <Text
          maw={{ base: 420, lg: 500 }}
          c="red"
          px="24px 0"
          mx="auto"
          fw="500"
          ta="center"
          fz={{ base: 14, sm: 14, md: 14, lg: 16, xl: 18 }}
        >
          NOTE: All tasks will be reassigned to the respective project spouse
          upon account deletion.
        </Text>
      )}

      <Group justify="flex-end" gap={isMobile ? 10 : 24} grow mt={24}>
        <Button
          ff="Inter"
          fw="500"
          fz={{ base: 14, sm: 14, md: 16, lg: 18, xl: 18 }}
          radius="100px"
          size={is4k ? "xl" : isMobile || isTablet ? "md" : "lg"}
          maw="max-content"
          h={{ base: 48, md: 50 }}
          variant="outline"
          color="red"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          ff="Inter"
          fw="500"
          fz={{ base: 14, sm: 14, md: 16, lg: 18, xl: 20 }}
          radius="100px"
          color="var(--mantine-color-primaryGreen-3)"
          size={is4k ? "xl" : isMobile || isTablet ? "md" : "lg"}
          maw="max-content"
          h={{ base: 48, md: 50 }}
          onClick={handleContinue}
        >
          Proceed
        </Button>
      </Group>
    </Stack>
  );
};

export const DeleteAccountUsingPasswordModal = ({
  handleDelete,
}: {
  handleDelete: (currentPassword?: string) => void;
}) => {
  const hform = useForm<CustomPasswordInputFieldType>({
    mode: "onChange",
    defaultValues: {
      newPassword: "",
    },
  });
  const currentPassword = hform.watch("newPassword");
  return (
    <Stack
      pt={{ base: 24, md: 30 }}
      pb={15}
      w="100%"
      gap={16}
      h="100%"
      align="center"
      justify="center"
    >
      <CustomPasswordInputField
        hform={hform}
        name="newPassword"
        placeholder="Current Password"
        w="100%"
        rules={{
          required: true,
          validate: {
            validate: validatePasswordLength,
          },
        }}
      />
      <Box w="100%">
        <Button
          ff="Inter"
          fw="500"
          color="red"
          fz="inherit"
          radius="100px"
          w="100%"
          size="50px"
          onClick={() => {
            handleDelete(currentPassword);
          }}
        >
          Delete Account
        </Button>
      </Box>
    </Stack>
  );
};

export const DeleteAccountUsingTextInputModal = ({
  handleDelete,
}: {
  handleDelete: (currentPassword?: string) => void;
}) => {
  const hform = useForm<CustomInputFieldType>({
    mode: "onChange",
    defaultValues: { confirmationText: "" },
  });
  const { isMobile, isTablet } = useScreenBreakpoints();
  const { handleSubmit } = hform;
  const { showToast } = useCustomToast();
  const checkIsValid = (data: CustomInputFieldType) => {
    if (data.confirmationText !== "Delete permanently") {
      showToast({
        status: ToastStatus.error,
        id: "errorToastId",
        message: "Field does not match 'Delete permanently'.",
      });
      return;
    }
    handleDelete();
  };

  return (
    <form
      onSubmit={handleSubmit((data) => {
        checkIsValid(data);
      })}
    >
      <Stack
        pt={15}
        pb={15}
        w="100%"
        gap={20}
        h="100%"
        align="center"
        justify="center"
      >
        <Text>
          To confirm deletion please type{" "}
          <Text fw={700} span>
            Delete permanently
          </Text>{" "}
          in below field:
        </Text>
        <CustomInputField
          hform={hform}
          name="confirmationText"
          placeholder='"Delete permanently"'
          w="100%"
          rules={{
            required: true,
          }}
          styles={{
            input: {
              fontSize: isMobile || isTablet ? 14 : 16,
              border: "0.5px solid #000",
              borderRadius: 100,
              height: 50,
            },
          }}
        />
        <Box w="100%">
          <Button
            w="100%"
            ff="Inter"
            fw="400"
            type="submit"
            fz="inherit"
            radius="100px"
            color="red"
            size="50px"
          >
            Delete Account
          </Button>
        </Box>
      </Stack>
    </form>
  );
};
