import {
  BottomHeadlineTextDesktopView,
  BottomHeadlineTextMobileView,
} from "@/components/BottomHeadlineText";
import LandingPageHeader from "@/components/header/LandingPageHeader";
import {
  WeddingDateNotSetHome,
  WeddingDateNotSetHomeMobile,
} from "@/components/WeddingDateNotSetHome";
import {
  WeddingDateSetHome,
  WeddingDateSetHomeMobile,
} from "@/components/WeddingDateSetHome";
import { Box, Stack } from "@mantine/core";

const WeddingDate = () => {
  return (
    <Box pos="relative" w="100%" mih="100vh">
      <LandingPageHeader />
      <Box display={{ base: "none", md: "block" }}>
        <WeddingDateSetHome />
        <WeddingDateNotSetHome />
        <BottomHeadlineTextDesktopView />
      </Box>
      <Stack h="100%" gap="0px" display={{ base: "flex", md: "none" }}>
        <WeddingDateSetHomeMobile />
        <WeddingDateNotSetHomeMobile />
        <BottomHeadlineTextMobileView />
      </Stack>
    </Box>
  );
};

export default WeddingDate;
