import { Text, TextProps } from "@mantine/core";

const CustomParagraph = ({
  children,
  id,
  ...props
}: { children: React.ReactNode; id?: string } & TextProps) => {
  return (
    <Text
      fz={{ base: 18, sm: 24 }}
      lh="1.41"
      ff="Inter"
      c="customBlack.6"
      fw={400}
      {...props}
    >
      {children}
    </Text>
  );
};
export default CustomParagraph;
