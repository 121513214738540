import HeroSection from "@/components/blogs/allBlogs/HeroSection";
import WeddingDate from "@/containers/Homepage";
import { getBackendUrl } from "@/env";
import { GetBlogsResponseType } from "@/types/blog";
import { Stack } from "@mantine/core";
import { GetServerSideProps } from "next";
import Head from "next/head";
import Error from "next/error";

export default function Home({ blogData }: { blogData: GetBlogsResponseType }) {
  if (!blogData) {
    return <Error statusCode={404} />;
  }
  return (
    <>
      <Head>
        <title>
          Uthsav - Wedding planning | Event planning | Indian Weddings
        </title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <meta
          name="description"
          content="Uthsav - Wedding planning | Event planning | Indian Weddings"
        />
      </Head>

      <Stack h="100%" gap="0px" align="center">
        <WeddingDate />
        <HeroSection latestBlogData={blogData} />
      </Stack>
    </>
  );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  try {
    // TODO : Update this to tanstack query
    const response = await fetch(
      `${getBackendUrl()}/blog/all/?pageNumber=1&pageSize=1&isOrderDesc=true`
    );

    if (!response.ok) {
      return {
        notFound: true,
      };
    }

    const data = await response.json();
    if (data.results.length === 0) {
      return {
        notFound: true,
      };
    }
    return {
      props: {
        blogData: data.results[0],
      },
    };
  } catch (error) {
    console.error("Failed to fetch blog data:", error);
    return {
      notFound: true,
    };
  }
};
