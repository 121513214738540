import { useRouter } from "next/router";
import { useApi } from "@/hooks/useApi";
import { getQueryClient } from "@/api";
import { openSuccessModal } from "@/components/common/modals";
import { Image } from "@mantine/core";

export const useLogout = ({
  onSuccessCallbackFunction,
}: {
  onSuccessCallbackFunction: () => void;
}) => {
  const router = useRouter();
  const { makeApiCall } = useApi();
  const onLogout = async () => {
    return makeApiCall({
      fetcherFn: async () => {
        const response = await getQueryClient().auth.logout.mutation({
          body: {},
        });
        return response;
      },
      onSuccessFn: () => {
        router.push("/").then(() => {
          onSuccessCallbackFunction();
          openSuccessModal({
            header: "Successfully Signed Out",
            message: "Your account has been signed out successfully.",
            icon: (
              <Image
                src={"/images/sign-out.svg"}
                alt="Sign out"
                w="100px"
                display={"inline-block"}
              />
            ),
          });
        });
      },
      showLoader: true,
    });
  };

  return { onLogout };
};
