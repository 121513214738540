import { PasswordMatchEnum } from "@/types/profile";
import { Text, TextProps } from "@mantine/core";
import isEqual from "lodash/isEqual";

const PasswordMatchIndicator = ({
  password,
  confirmPassword,
  ...props
}: {
  password: string;
  confirmPassword: string;
} & TextProps) => {
  const doPasswordsMatch = () => {
    if (!password || !confirmPassword) {
      return "";
    }
    if (isEqual(password, confirmPassword)) {
      return PasswordMatchEnum.MATCH;
    }
    return PasswordMatchEnum.NOT_MATCH;
  };
  const passwordMatch = doPasswordsMatch();
  if (!passwordMatch) {
    return <></>;
  }
  return (
    <Text
      fz="xs"
      px="10px"
      py="5px"
      tt="capitalize"
      ff="Inter"
      c={passwordMatch === PasswordMatchEnum.MATCH ? "green" : "red"}
      {...props}
    >
      {passwordMatch}
    </Text>
  );
};
export default PasswordMatchIndicator;
